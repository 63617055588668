<template lang="pug">
.live-demo-bg
  .live-demo-graphic
  .live-demo-graphic-emojis
  .live-demo-pretitle FREE WEBINAR
  .live-demo-title
    .r1 OptiMonk 101:
    .r2 Live walk-through
    .r3 + Q&A
  .live-demo-body
    .r1 Join us for a live demo and learn to create
    .r2 high-performing popups in 30 minutes.
  om-button.live-demo-button(@click="liveDemoPage") Details
</template>
<script>
  export default {
    methods: {
      liveDemoPage() {
        window.open(
          'https://www.optimonk.com/optimonk-101/?utm_source=login&utm_medium=banner&utm_campaign=left-section',
        );
      },
    },
  };
</script>
<style lang="sass">
  @import '../../sass/variables/_colors.sass'
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700;800;900&display=swap')

  @media screen and (min-width: 1145px)
    .brand-wrapper-left
      width: 480px !important

  @media screen and (max-width: 1145px)
    .brand-wrapper-left
      display: none !important
    .brand-wrapper-right
      flex: 0 0 100%
      max-width: 100%

  @media screen and (min-height: 1000px)
    .live-demo-bg
      justify-content: center

      .live-demo-graphic
        top: auto !important
        bottom: 0 !important
      .live-demo-graphic-emojis
        top: auto !important
        bottom: 36px !important
      .live-demo-pretitle
        margin-top: -200px !important

  .brand-wrapper-left.brand-login-wrapper-left-en
    padding-top: 0 !important

  .live-demo-bg
    display: flex
    flex-direction: column
    align-items: center
    gap: 20px
    background-image: url('~@/assets/admin/img/live-demo-bg.png')
    background-color: #fff
    background-position: top
    background-repeat: no-repeat
    background-size: cover
    z-index: 1
    width: 100%

    .live-demo-pretitle
      margin-top: 120px
      font-family: 'Poppins'
      font-style: normal
      font-weight: 500
      font-size: 16px
      text-align: center
      text-transform: uppercase
      background: linear-gradient(90deg, #7C10EE 1.35%, #ED5A29 98.65%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent
      background-clip: text
      text-fill-color: transparent

    .live-demo-title
      font-family: 'Poppins'
      font-style: normal
      font-weight: 700
      font-size: 36px
      line-height: 48px
      text-align: center
      color: #312340

    .live-demo-body
      font-family: 'Roboto'
      font-style: normal
      font-weight: 400
      font-size: 18px
      line-height: 26px
      leading-trim: both
      text-edge: cap
      align-items: center
      text-align: center
      color: #5B5266

    .live-demo-button
      margin-top: 10px
      font-family: 'Roboto'
      font-size: 16px
      line-height: 28px
      justify-content: center
      align-items: center
      padding: 10px 16px
      color: #FFF
      background: linear-gradient(180deg, #ED5A29 0%, #F17E58 100%)
      box-shadow: 0px 4px 10px rgba(237, 90, 41, 0.22), 0px 0px 16px rgba(237, 90, 41, 0.12)
      border-radius: 10px
      &:hover
        text-decoration: none

    .live-demo-graphic
      position: absolute
      top: 420px
      width: 100%
      height: 527px
      background-image: url('~@/assets/admin/img/live-demo-grafika.png')
      background-position: center
      background-repeat: no-repeat

    .live-demo-graphic-emojis
      position: absolute
      top: 510px
      width: 100%
      height: 400px
      background-image: url('~@/assets/admin/img/live-demo-emojis.png')
      background-position: center
      background-repeat: no-repeat
</style>
